
.container{
  position: absolute;
  left:0;
  right:0;
  top:0px;
  height:0;
  overflow: hidden;
  //display:flex;
  //justify-content: center;
  //align-items: center;
  //background-color: red;
  //border: 5px black dotted;
  opacity:1;
  //z-index: 10e99;
  z-index: calc(var(--z-home) + 1);

}

.record {
  //border:1px dotted black;
  z-index: calc(var(--z-home) + 2);
}