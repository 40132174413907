
.default {
  font-size:14px;
  font-face: Terminal;
}

.log {
  @extend .default;
  overflow-y:scroll;
  overflow-wrap: break-word;
  width:100%;
  height:300px;
  background-color: lightgrey;

}


.playbackState {
  @extend .default;
  position:absolute;
  right:100px;
  top:1px;
  opacity:0.2;
  background-color:white;
  padding:1px;
  z-index:  var(--z-highest);
;
  text-transform: uppercase;
}
