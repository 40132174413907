.default {
  display:grid;
  grid-template-columns: 30px 1fr 80px;
  align-items: center;
  border-top: 1px solid var(--heavyColor);
  justify-items: flex-start;
  padding:4px 0px 4px 0px;
  text-decoration: none;
  color: black;
  transition: 0.1s;
  .index {
    grid-column: 1/2;
    opacity:0.5;

  }

  .title {
    grid-column:2/3;
  }

  .artists {
    opacity:0.5;
  }

  .duration {
    opacity:0.5;
    display: inline-block;
    grid-column:2/3;
    font-style: italic;
  }

  .more {
    width:100%;
    height: 100%;
    grid-column:3/5;
    opacity:1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .icon {
      margin-right: 6px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width:80px;
      height:30px;
      color: var(--textColor);
    }
  }


  .more:hover {
    z-index:var(--z-highest);
    opacity:1;
  }

  .details {
    transition: all 0.5s;
  }
  @media (hover: none) {
    .more {
      opacity: 1;
    }
  }
}

.default:hover {
  text-decoration:none;
  color: var(--textColor);
  //opacity:0.8;
}

.default:hover > .more {
  opacity:1;
}


  @media (hover: none) {
  .default {
    color: black;
  }
}
