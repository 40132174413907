.container {
  background-color: var(--lighterBackgroundColor1)
}

.catalogs {
  //display: flex;

  font-size: 14px;
  margin-left:8px;
  //border-left:6px solid black;
  //padding-left:6px;
  //margin-top:12px;
  .header {
    //color: var(--lightTextColor);
    font-weight:bold;
    text-transform:uppercase;
    font-size:10px;
    margin:-12px 0px 0px 4px;
  }
}

.catalog {
  cursor: pointer;
  margin-left:4px;
  margin-right:12px;
  margin-top: -4px;
  //border-radius:18px;
  padding:0px;
  .sourceIndicator {
    display:inline-block;
    height:8px;
    width:8px;
    margin-top:-4px;
    border-radius: 4px;
  }
}

.activeCatalog {
  @extend .catalog;
}
.inactiveCatalog {
  @extend .catalog;
}
.header {
  font-weight: bold;
  font-size: 28px;
  margin-left: 12px;
  padding-top: 10px;
  margin-bottom: 0px;
  margin-top: 5px;
}

@media (hover: none) {
  .header {
    margin-bottom: 0px;
  }
}

.headerNotes {
  font-weight:normal;
  font-size:12px;
  font-style:italic;
  margin-left: 12px;
 }
.question-circle {
  margin-left:4px;
  color:lightblue;
  font-size: 12px;
}
