@import "../../stylesheets/variables.scss";
@import "../../stylesheets/mixins.scss";

.loading {
  padding: 10px;
}

.default {
  margin: 8px 6px 6px;
  .header {

    //width: 100%;
    //display: flex;
    //flex-direction: row;
    //margin-top: -2px;
    //margin-bottom: 6px;

    .inner {

      //border-bottom: 1px dotted white;
      width: 100%;
      display: grid;
      grid-template-columns: auto auto;
      grid-template-areas: "left right";
      justify-content: space-between;
      align-items: baseline;

      .left {
        grid-area: left;
        align-self: start;
        //display: inline-block;
      }

      .right {

        display: flex;
        flex-direction: row;
        grid-area: right;
        align-self: end;
        transform: translateY(-1px);
        margin-right: 4px;
        //justify-content: end;
      }

      .dropdown {
        //color: var(--textColor);
        //margin-right: 5px;
        //height: 20px;
        //margin-bottom: 14px;

        select {
          background-color: transparent;
          //border-radius: 6px;
          margin-top: 4.5px;
          //padding: 6px;
          border: 0;
          font-size: 13.5px;
          //font-style: italic;
          color: #555;
        }
      }
    }
  }

  @media (hover: none) {
    .header {
      display: block;
      //margin-top: -16px;
      //margin-bottom: 13px;
    }
    .sortOptions {
      margin-left: 4px;
    }
  }

  .records {
    display: grid;
    grid-gap: 12px;
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr) );
    @include scrollbars(10px, var(--lighterBackgroundColor2), transparent);
  }

  @media only screen and (max-width: 550px) {
    .records {
      display: grid;
      grid-gap: 12px;
      grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
    }
  }

  .groups {
    @extend .default;

    .group {
      margin-bottom: 8px;

      .label {
        color: black;
        margin-top: 20px;
        //margin-bottom: -25px;
        margin-left: 0px;
        font-weight: bold;
        font-size: 18px;
        border-bottom: 6px black solid;

      }

      .records {
        margin: -5px;
        padding-bottom: 5px;

      }
    }

    @media (hover: none) {
      .group {
        margin-bottom: 20px;
      }
    }
  }

  .footer {
    margin-top: 5px;

    button {
    }

    .refreshButton {
      background-color: transparent;
      border: 6px;
      color: var(--lighterBackgroundColor1);
      font-weight: bold;
    }
  }
}

.default-s {
  @extend .default;

  .records {
    margin:0;
    display: grid;
    grid-column-gap: 0px;
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr) );

    @include scrollbars(10px, var(--lighterBackgroundColor2), transparent);
  }
}

.default-m {
  @extend .default;

  .records {
    display: grid;
    grid-gap: 12px;
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr) );
    grid-template-rows: repeat(auto-fill, 1fr);

    @include scrollbars(10px, var(--lighterBackgroundColor2), transparent);
  }

  @media only screen and (max-width: 550px) {
    .records {
      display: grid;
      grid-gap: 12px;
      grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
    }
  }
}

.default-l {
  @extend .default;

  .records {
    display: grid;
    grid-gap: 0px;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr) );
    grid-template-rows: repeat(auto-fill, 1fr);

    @include scrollbars(10px, var(--lighterBackgroundColor2), transparent);
  }

  @media only screen and (max-width: 550px) {
    .records {
      display: grid;
      grid-gap: 0px;
      grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
    }
  }
}

.homepage-s {
  @extend .default-s;
  .records {
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr) );

    column-gap:6px
  }
}

.tabHighlight {
  border: 10px solid red;
}

.playlists-m {
  @extend .default;
  //display: auto;
  // flex-direction: row;
  .records {
    //display: auto;
    //flex-wrap: wrap;
  }
}
