

.container {
  padding-top:8px;
  background-color: var(--lighterBackgroundColor1);
}
.record-container {
  display: flex;
  align-items: start;
  justify-content: center;

}
