@import "../../stylesheets/variables.scss";
@import "../../stylesheets/mixins.scss";
@import "../../stylesheets/animations.scss";

@keyframes playbackStrobe {
  from {
    opacity: 0.1;
  }

  to {
    opacity: 1;
  }
}

.default {
  --turnOverPerspective: 10000px;
  //transition: all 0.2s;
  margin-top: 10px;
  position: relative;
  .cover {
    transition: transform var(--recordAnimationDuration), opacity var(--recordAnimationDuration) ;
    cursor: pointer;
    position: relative;
    img {
      padding: 0px;
      margin: 0px;
    }
    .previewing {
      position: absolute;
      right: 15px;
      top: 10px;
      padding: 5px;
      background: var(--lighterBackgroundColor2);
      font-size: 8px;
      text-transform: uppercase;
      border-radius: 5px;
    }
  }
  .playback-state {
    padding: 4px 6px 4px 6px;
    font-weight: bold;
    font-size: 12px;
    opacity: 0.7;
    border-radius: 12px;
    text-transform: uppercase;
    background-color: var(--lighterBackgroundColor3);
    color: var(--textColor);
    position: absolute;
    right: 7px;
    top: 6px;
    z-index: var(--z-highest);
  }
  .spine {
    .lines {
      .line {
        color: var(--textColor);
        display: none;
        width: 100%;
      }
      .record-id {
        display: none;
        font-size: 14px;
      }
      .artist-and-album-name {
        @extend .line;
      }
      .artist-name {
        @extend .line;
        font-weight: bold;
        display: inline-block;
        .inlib {
          display: none;
        }
      }
      .name {
        @extend .line;
      }
      .label {
        @extend .line;
        .labelArtist {
          //background: var(--lighterBackgroundColor2);
          margin-left: 6px;
          color: var(--lightTextColor);
          font-size: 12px;
          transform: translate(0, -20);
        }
      }
      .release-date {
        @extend .line;
        display: block;
      }
      .release-date-absolute {
        @extend .line;
        display: none;
      }
    }
  }
}

.library {
  @extend .default;
  .cover {
    img {
      -webkit-user-drag: none;
      -khtml-user-drag: none;
      -moz-user-drag: none;
      -o-user-drag: none;
      user-drag: none;
      padding: 0px;
      margin: 0px;
      width: 100%;
      max-height: 100%;
      object-fit: cover;
    }
    .placeHolder {
      margin-top: 15px;
      margin-left: 7px;
      background: lightblue;
    }
  }
  .spine {
    margin-top: 4px;
    margin-left: 4px;

    .lines {
      display: flex;
      flex-direction: column;
      grid-template-columns: 1fr 20px;
      margin-top: 2px;
      grid-column: 1/3;

      .line {
        display: block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .record-id {
        overflow: visible;
        white-space: normal;
        text-overflow: initial;

        display: none;
      }
      .artist-and-album-name {
        text-transform: uppercase;
        order: 1;
        font-weight: bold;
        font-size: 28px;
        display: none;
      }
      .artist-name {
        order: 1;
        font-weight: bold;
      }
      .name {
        order: 2;
        display: none;
        //font-weight: bold;
      }
      .label {
        order: 3;
        display: none;
      }
      .release-date {
        order: 4;
        display: none;
      }
      .release-date-absolute {
        @extend .line;
        order: 4;
        display: none;
      }
    }
    .buttons {
      display: none;
      grid-row: 1;
      grid-column: 2/3;
    }
    .add-button {
      display: none;
    }
    .delete-button {
      display: block;
    }
  }
}

.library-bold {
  @extend .library;
  .cover {
    background-color: transparent;
    border-radius: 20px;
    border: 6px solid var(--heavyColor);
    img {
      border-radius: 14px;
    }
  }
  .spine {
    display: grid;
    grid-template-columns: 1fr 30px;
    .lines {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      .line {
        font-size: 14px;
      }
      .name {
        display: inline-block;
      }
      .label {
        display: none;
      }
      .release-date-absolute {
        display: none;
      }
    }
  }
}

.player {
  @extend .library-bold;
  padding-bottom: 10px;
  .spine {
    .lines {
      .release-date-absolute {
        display: block;
      }
      .label {
        display: none;
      }
    }
  }
}

.big-playing-record {
  --turnOverDuration: 200ms;
  @extend .default;
  z-index: 0;
  display: flex;
  .cover {
    justify-self: center;
    align-items: center;
  }
  .spine {
    display: none;
  }
}

.big-picked-record {
  @extend .big-playing-record;
}

.button {
  margin-right: 4px;
  font-size: 28px;
}

.button-hidden {
  margin-right: 10px;
  font-size: 28px;
  opacity: 0;
}

@media (hover: none) {
  //.button-hidden {
  //  opacity: 1;
  //}
}

.record-page {
  @extend .big-playing-record;
}
