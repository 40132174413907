body {
    background-color: #f6f6f6;
    overscroll-behavior-x: contain;
    overflowX: hidden;

}


/* SHELF-SPECIFIC STUFF */
.search-results {
    margin-left:-4px;
    margin-top:0px;
    position:absolute;
    background-color:white;
    width:100%;
    padding:4px;
}

.search-result-record {

}

.shelf {
    display: flex;
    flex-wrap: wrap;
}
.shelf-container {
    margin-top:12px
}

a:hover {
    text-decoration: underline;
    color:lightblue;
}

.now-playing-block {
    float:right;
    padding:10px;
}

.spacer {
    height:24px
}

.search-container {
    margin:4px;
    margin-top:6px;
    margin-bottom:-3px;
}

.search-field {
    width:100%;
    border:0px;
}


@media (min-width:415px) {
    .search-field {
        width:400px
    }
}

h2 {
    margin-top:0px;
    font-size:18px;
    font-weight: bold;
}
h3 {
    font-weight: bold;
    margin-left:4px;
    line-height: 10px;
    font-size:14px;
    color: #555
}

.hidden {
    opacity:0
}

*:focus {
    outline: none;
}

.rebase2 {

}
