@import "../../stylesheets/variables.scss";
@import "../../stylesheets/mixins.scss";

.default {
  //z-index: 10000;
  --turnOverPerspective: 10000px;
  transition: height 1s;
  //visibility: hidden;
  background-color: var(--lighterBackgroundColor1);
  border-radius: 12px;
  padding: 6px;
  //border: 5px solid var(--heavyColor);
  font-size: 12px;
  overflow-y: scroll;
  //overflow-x:hidden;
  //height:0px;
  width: 200px;
  margin-top: 6px;
  //height:250px;
  @include scrollbars(0px, var(--lighterBackgroundColor2), transparent);
  .header {
    font-size: 14px;
    .artist-name {
      overflow-x: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .name {
      overflow-x: hidden;
      font-weight: bold;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .playPause {
    }
  }
  .footer {
    font-style: italic;
    padding: 5px;
    font-size: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .label {
    }
    .release-date {
    }
  }
  .cover-header {
    display: grid;
    grid-template-columns: 110px 1fr 40px;
    padding: 7px;
    margin-right: 0px;
    //background-color: var(--backgroundColor);
    .front-cover {
      grid-column: 1/2;
     margin-left:-6px;
      //margin-left:6px;
      img {
        padding: 0px;
        margin: 0px;
      }
    }
    .playback-state-name {
      text-transform: uppercase;
      color: var(--heavyColor);
      font-size: 12px;
      font-weight: bold;
      //height:14px;
    }
    .big-meta-text {
      //margin-left:10px;
      grid-column: 2/3;
      font-size: 16px;
      .big-label-name {
        order: 3;
        font-style: italic;
        .big-label-area {
          display: inline;
          font-size: 12px;
        }
        @media (hover: none) {
          big-label-area {
            display: none;
          }
        }
      }
      .big-release-date {
        order: 4;
        font-style: italic;
      }
      .big-artist-name {
        margin-top: -2px;
        order: 2;
      }
      .big-name {
        font-weight: bold;
        order: 1;
        font-size: 20px;
      }
    }

    .cornerButtons {
      margin-top: 5px;

      display: flex;
      //width: 190px;
      justify-self: flex-end;
      grid-column: 3/4;
      font-size: 45px;
      color: var(--darkerBackgroundColor);

    }
    .cornerButton {
      //margin-right: 8px;
      //background-color: var(--lighterBackgroundColor3);
      // padding: 4px 20px 4px 20px;
      padding-top: 6px;
      height: 60px;
      //width: 78px;
      display: flex;
      justify-content: center;
      align-content: center;
      border-radius: 8px;
      margin-left: 12px;
    }
    .buttons {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      margin-bottom: 4px;
      margin-top: 2px;

      .button {
        border: 0px;
        background-color: var(--darkerBackgroundColor);
        //color: var(--darkerBackgroundColor);
        min-width: 44px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 4px;
        //max-width:64px;
        border-radius: 4px;
        font-size: 18px;
        //margin-right: 5px;
      }
      .button:not(:last-child) {
        margin-right: 5px;
      }
      @media (hover: none) {
        .button {
          width: 40px;
        }
      }

      a {
        border: 0px;
        background-color: var(--lighterBackgroundColor2);
        margin: 6px 10px 8px 0px;
        width: 164px;
        border-radius: 4px;
        font-size: 18px;
        padding: 3px 20px 3px 20px;
        i {
          color: #333; //var(--textColor);
        }
      }
    }
    .more-info {
      .description {
        .date {
        }
        .credit {
        }
      }
    }
  }
  }

  .blurb {
    .header {
      text-transform: uppercase;
      opacity: 1;
      font-weight: bold;
      border-bottom: 1px solid black;
      font-size: 12px;
      padding: 8px 0px 8px 0px;
    }
    .short {
      font-style: italic;
      font-weight: bold;
    }
    .standard {
      font-style: italic;
    }
  }
  .debug {
    font-size: 12px;
    font-family: monospace;
    opacity: 0;
  }
  .debug:hover {
    opacity: 1;
  }
  .cover-art {
    @include scrollbars(10px, var(--lighterBackgroundColor2), transparent);
    margin: -8px 0px 4px 0px;
    display: grid;
    overflow-x: scroll;
    grid-template-columns: repeat(100, 1fr);
    overflow-y: hidden;
    .cover {
      cursor: pointer;
    }
  }



.library-bold {
  @extend .default;
}
.catalogNew {
  @extend .default;
}

.artist {
  @extend .default;
}

.searchResults {
  @extend .default;
}

.big-playing-record {
  --turnOverDuration: 150ms;
  @extend .default;
}

.big-picked-record {
  --turnOverDuration: 150ms;
  @extend .default;
  .cancel {
    display: none;
  }
}

.record-page {
  @extend .big-playing-record;
}
.catalogShelf {
  margin-top: 10px;
}

// .playlists {
//   opacity: 0.5;
//   margin-top: 10px;
//   .cover-header {
//     .big-meta-text {
//       .big-release-date {
//         display: none;
//       }
//     }
//   }
// }

.sectionHeader {
  color: var(--textColor);
  font-size: 14px;
  text-transform: uppercase;
  font-weight: bold;
  padding-left: 8px;
}

.cancel {
  float: right;
  font-size: 20px;
  z-index:  var(--z-highest);
  //margin-right:-20px;

  //padding:20px;
}

@media (hover: none) {
  .default {
    border: 0px;
    border-radius: 0;
  }
}


.dismiss {
  position: absolute;
  right: 10px;
  top: 13px;
  z-index:  var(--z-highest);
  width: 50px;
  height: 50px;
  display: flex;
  align-content: flex-end;
  justify-content: flex-end;
  .hitSlop {
    width: 50px;
    height: 50px;
    display: flex;
    align-content: flex-end;
    justify-content: flex-end;
  }
  .circle {
    opacity: 0.8;
    width: 30px;
    height: 30px;
    background-color: var(--backgroundColor);
    color: var(--heavyColor);
    border-radius: 15px;
    display: flex;
    align-content: center;
    justify-content: center;
  }
  .icon {
    transform: translate(0, -9);
    font-size: 28px;
    margin-top: -7px;
    transition: all 0.1s;
    opacity: 1;
  }
  .icon:hover {
    opacity: 1;
  }
}
