
.container {
  position: absolute;
  bottom:0;
  left:0;
  right:180px;
  max-height: 150px;
  //background-color: white;
  z-index:  calc(var(--z-highest) + 2);
  opacity: 0.7;
  .values {
    display:flex;
    .value {
      padding:4px;
      margin:6px;
      background-color: lightblue;
      border-radius: 6px;
    }
  }
}