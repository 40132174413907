@import "../../stylesheets/variables.scss";
@import "../../stylesheets/mixins.scss";

.default {
  .icon {
    font-size: 24px;
    color: var(--lighterBackgroundColor1);
    transform: translate(0px,7px) scale(1.85);

    margin-right:16px;
    margin-left: 8px;
  }

  .containerContainer {
    display: flex;
    flex-direction: row;
    .container {
      flex:99;
      background-color: var(--lighterBackgroundColor1);
      border-radius: 8px;
      padding: 4px;
      margin: 6px;
      margin-bottom: 0px;
    }
    .inputContainer {
      position: relative;
      margin: 4px 0px 1px 4px;
      input {
        padding: 0;
        text-indent: 30px;
        width: 100%;
        color: var(--textColor);
        background-color: var(--lighterBackgroundColor1);
        border: 0px;
      }

      input::placeholder {
        color: var(--lighterBackgroundColor2);
      }
    }
  }


  .controls {
    flex:1;

    .pushButtonSwitch {

    }
  }


  .searchIcon {
    position: absolute;
    color: var(--lighterBackgroundColor2);
    opacity: 0.5;
    top: -5px;
    left: 0px;
    font-size: 20px;
  }

  .clearIcon {
    position: absolute;
    color: var(--textColor);
    opacity: 0.5;
    top: -5px;
    right: 0px;
    font-size: 20px;
  }

  .hints {
    display: inline;
    margin-top: 40px;
    //display:flex;
    //flex-direction:row;
    //align-items:flex-start;
  }

  .hint {
    font-size: 16px;
    //display:flex;
    //align-items:flex-start;
    //width:100%;
    background-color: var(--backgroundColor);
    color: var(--textColor);
    border-radius: 16px;
    margin-left: 4px;
    margin-bottom: 4px;
    margin-top: 4px;
    border: px solid var(--heavyColor);
  }

}

.homepage {
  @extend .default;
  .containerContainer {
    .container {
      flex:99;
      background-color: transparent;
      border: 2px solid var(--lighterBackgroundColor1);
      border-radius: 0px;
      padding: 4px;
      margin: 6px;
      margin-bottom: 0px;
      .inputContainer {
        input {
          padding: 0;
          text-indent: 30px;
          width: 100%;
          color: var(--textColor);
          background-color: transparent;
          border: 0px;
        }
      }
    }
  }



}
