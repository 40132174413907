@import "../../stylesheets/variables.scss";
@import "../../stylesheets/mixins.scss";
@import "../../stylesheets/animations.scss";


@mixin library-bold {
  margin-bottom: 8px;
  .cover {
    cursor: pointer;
    position: relative;
    transition: transform var(--recordAnimationDuration),
    opacity var(--recordAnimationDuration),
    border-color 1s;
    background-color: transparent;
    border-radius: 20px;
    border: 6px solid var(--heavyColor);

    img {
      padding: 0;
      margin: 0;
      border: 0;
      border-radius: 14px;
      -webkit-user-drag: none;
      -khtml-user-drag: none;
      -moz-user-drag: none;
      -o-user-drag: none;
      user-drag: none;
      width: 100%;
      max-height: 100%;
      object-fit: cover;
    }


    .placeHolder {
      margin-top: 15px;
      margin-left: 7px;
      background: lightblue;
    }

    .previewing {
      position: absolute;
      right: 10px;
      top: 10px;
      padding: 5px;
      background: var(--lighterBackgroundColor2);
      font-size: 8px;
      text-transform: uppercase;
      border-radius: 5px;
    }

    .coverDebugOverlay {
      position: absolute;
      left: 10px;
      top: 10px;
      background-color: navajowhite;
      padding: 10px;
    }
  }

  .spine {
    margin-top: 4px;
    margin-left: 4px;

    .record-id {
      display: none;
      font-size: 14px;
    }

    .extra {
      display: none;
      font-size: 14px;
      font-style: italic;
    }

    .lines {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      %line {
        user-select: none;
        color: var(--textColor);
        width: 100%;
        font-size: 14px;
        display: block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .record-id {
        overflow: visible;
        white-space: normal;
        text-overflow: initial;

        display: none;
      }

      .artist-and-album-name {
        @extend %line;
        text-transform: uppercase;
        order: 1;
        font-weight: bold;
        font-size: 28px;
        display: none;
      }

      .artist-name {
        @extend %line;
        order: 1;
        font-weight: bold;
        display: inline-block;

        .inlib {
          display: none;
        }
      }

      .name {
        @extend %line;
        order: 2;
        display: inline-block;
      }

      .label {
        @extend %line;
        order: 3;
        display: none;
        .labelArtist {
          //background: var(--lighterBackgroundColor2);
          margin-left: 6px;
          color: var(--lightTextColor);
          font-size: 12px;
          transform: translate(0, -20);
        }
      }

      .release-date {
        @extend %line;
        order: 4;
        display: none;
      }

      .release-date-absolute {
        @extend %line;
        order: 4;
        display: none;
      }
      .json {
        //overflow-wrap: break-word;
        overflow: visible;
        order: 5;
        border: 2px dotted green;
        white-space: normal;
        font-size: 8px;
      }
    }
  }
}

.library-bold-s {
 @include library-bold;
  margin-bottom: 0px;

  .cover {
    border: 0px;
    border-radius: 0;

    img {
      border-radius: 0;
    }
  }

  .spine {
    display: none;

    .lines {

      .artist-name {
        display: none;
      }

      .name {
        display: none;
      }

      .release-date {
        display: none;
      }

      .release-date-absolute {
        display: none;
      }
    }
  }
}
//
//.library-bold-artist-overhead-s {
//  @extend .library-bold-s;
//
//  .spine {
//    overflow: auto;
//    white-space: normal;
//    text-overflow: unset;
//    display: block;
//    margin-top: 0px;
//    margin-left: 0px;
//
//    .lines {
//      .artist-name {
//        height: 16px;
//        display: block;
//        font-weight: normal;
//        text-transform: uppercase;
//        font-size: 12px;
//        margin-top: -2px;
//        opacity: 0.7;
//      }
//
//      .name {
//        display: none;
//      }
//
//      .release-date {
//        display: none;
//      }
//
//      .release-date-absolute {
//        display: none;
//      }
//    }
//  }
//}

.library-bold-m {
 @include library-bold;
}
//
//.library-bold-l {
// @include library-bold;
//
//  .cover {
//    border: 0px;
//    border-radius: 0;
//
//    img {
//      border-radius: 0;
//    }
//  }
//
//  .spine {
//    display: none;
//  }
//}


.library-bold-xs {
 @include library-bold;

  .cover {
    display: none
  }

  .spine {
    .lines {
      .spineLabel {
        display: block;
      }

      .name {
        display: none;
      }

      .artist-name {
        display: none;
      }
    }
  }
}

.selectionAnimation {
 @include library-bold;

  .spine {
    display: none;
  }
}

.library-bold-embedded-in-big {
 @include library-bold;

  .spine {
    display: grid;
    grid-template-columns: 1fr 30px;

    .lines {
      .release-date {
        display: block;
      }
    }
  }
}

.player {
 @include library-bold;
  padding-bottom: 10px;

  .spine {
    .lines {
      .release-date-absolute {
        display: block;
      }

      .label {
        display: none;
      }
    }
  }
}

//
//.button {
//  margin-right: 4px;
//  font-size: 28px;
//}
//
//.button-hidden {
//  margin-right: 10px;
//  font-size: 28px;
//  opacity: 0;
//}
//
//@media (hover: none) {
//  //.button-hidden {
//  //  opacity: 1;
//  //}
//}



.playlist {
 @include library-bold;
  //border: 3px solid var(--heavyColor);
  border-radius: 8px;
  padding: 3px;

  .cover {
    //display: none;
    //height: 0px;
  }

  .spine {
    //display: flex;
    //justify-content: center;
    //align-items: center;
    //border-radius: 8px;
    //height: auto;
    //padding: 3px;
    //font-size: 18px;
    //color: var(--textColor);

    .lines {
      .line {

        height: auto;
        width: auto;
      }

      .artist-name {
        display: none;
      }

      .name {
        display: block;
      }

      .release-date {
        display: none;
      }

      .release-date-absolute {
        display: none;
      }
    }
  }
}

//
//.catalogNew {
// @include library-bold;
//  .cover {
//    background-color: transparent;
//    border-radius: 20px;
//    border: 6px solid var(--heavyColor);
//
//    img {
//      border-radius: 14px;
//    }
//
//    // }
//    // .cover {
//    //   img {
//    //     width: 50%;
//    //     padding: 0px;
//    //     margin: 0px;
//    //     object-fit: cover;
//    //     max-height: 100%;
//    //   }
//    .placeHolder {
//      margin-top: 15px;
//      margin-left: 0px;
//      background: lightblue;
//    }
//  }
//
//  .spine {
//    .sourceIndicator {
//      display: inline-block;
//      height: 8px;
//      width: 8px;
//      margin-top: -4px;
//      border-radius: 4px;
//    }
//
//    .lines {
//      .line {
//        font-size: 14px;
//        white-space: normal;
//      }
//
//      .record-id {
//        display: none;
//      }
//
//      .artist-name {
//        .inlib {
//          font-weight: normal;
//          font-style: italic;
//          margin-left: 6px;
//          font-size: 12px;
//          color: var(--lightTextColor);
//          transform: translate(0, -20);
//        }
//      }
//
//      .label {
//        //display: block;
//        //color: var(--textColor);
//        .labelArtist {
//          font-style: italic;
//          color: var(--lightTextColor);
//        }
//      }
//
//      .release-date {
//        display: block;
//        font-style: italic;
//        //color: var(--textColor);
//      }
//
//      .release-date-absolute {
//        display: none;
//      }
//    }
//
//    .add-button {
//      display: block;
//    }
//
//    .delete-button {
//      display: none;
//    }
//  }
//}

//
//.catalogNew-s {
// @include library-bold;
//
//  .cover {
//    border: 0px;
//    border-radius: 0;
//
//    img {
//      border-radius: 0;
//    }
//  }
//
//  .spine {
//    display: none;
//  }
//}

.catalogNew-m {
 @include library-bold;
    .spine {
      .sourceIndicator {
        display: inline-block;
        height: 8px;
        width: 8px;
        margin-top: -4px;
        border-radius: 4px;
      }

      .lines {
        .line {
          font-size: 14px;
          white-space: normal;
        }

        .record-id {
          display: none;
        }

        .artist-name {
          .inlib {
            font-weight: normal;
            font-style: italic;
            margin-left: 6px;
            font-size: 12px;
            color: var(--lightTextColor);
            transform: translate(0, -20);
          }
        }

        .label {
          //display: block;
          //color: var(--textColor);
          .labelArtist {
            font-style: italic;
            color: var(--lightTextColor);
          }
        }

        .release-date {
          display: block;
          font-style: italic;
          //color: var(--textColor);
        }

        .release-date-absolute {
          display: none;
        }
      }

      .add-button {
        display: block;
      }

      .delete-button {
        display: none;
      }
    }
}
//
//.catalogNew-l {
// @include library-bold;
//
//  .cover {
//    border: 0px;
//    border-radius: 0;
//
//    img {
//      border-radius: 0;
//    }
//  }
//
//  .spine {
//    display: none;
//  }
//}
//
//.catalogNew-m {
//  @extend .catalogNew;
//}

.catalogGrouped {
 @include library-bold;

  .spine {
    .lines {
      .label {
        display: none;
      }
    }
  }
}

.search-results {
 @include library-bold;
  //.cover {
  //  border: 0px;
  //  border-radius: 0px;
  //  img {
  //    border-radius: 0px;
  //  }
  //}
  .spine {
    .lines {
      .line {
        font-weight: normal;
        font-size: 12px;
        white-space: normal;
        width: 170px;
      }

      .label {
        display: block;
      }

      .release-date {
      }
    }

    .add-button {
      display: block;
    }

    .delete-button {
      display: none;
    }
  }
}

.artist {
 @include library-bold;

  .spine {
    .lines {
      .name {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        order: 1;
        //font-weight: bold;
      }

      .label {
        display: none;
      }

      .artist-name {
        display: none;
        order: 2;
        font-weight: normal;
      }

      .release-date {
        display: block;
        //color: #777;
      }
    }
  }
}

.homepage-s {
 @include library-bold;
.cover {
border: 0px;
  border-radius: 0px;
  img {
    border-radius: 0px;
  }
}
  .spine {
     display: none;
  }
}

.placeholder {
  color: var(--backgroundColor);
  opacity: 0;
  font-size: 14px;
  border-radius: 20px;
  background-color: var(--lighterBackgroundColor1);
  border: 3px solid var(--backgroundColor);
  padding: 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

}
