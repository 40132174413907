@import '../../stylesheets/variables.scss';
@import '../../stylesheets/mixins.scss';


.default {
  position: absolute;
  left:10px;
  bottom:10px;
  transition: opacity 0.1s;
  .pill {
    background-color: var(--lighterBackgroundColor1);
    padding:4px;
    border-radius: 12px;
    margin:4px;
    display:flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
  }

  .button {
    opacity:0.5;
    display:flex;
    justify-content: center;
    align-items: center;
    font-size: 36px;
  }
  .value {
    @extend .button
  }
  .button:hover {
    opacity:1
  }

  .playPauseButton {
    @extend .button;
  }
  .selectButton {
    @extend .button;
  }
  .tracksButton {
    @extend .button;
  }

}
@media (hover: none) {
  .default {
    display:none
  }
}


.library-bold {
  @extend .default;
}
