@import "../../stylesheets/variables.scss";
@import "../../stylesheets/mixins.scss";
.default {
  .cover {
    width: 100px;
    z-index:  var(--z-highest);
  }
  .container {
    .id {
      display: inline-block;
    }
    .text {
      .artistName {
      }
      .recordName {
      }
    }
    .controls {
      .prev {
      }
      .play {
        padding-left: 6px;
      }
      .pause {
        padding-left: 6px;
      }
      .stop {
        padding-left: 6px;
      }
      .next {
        padding-left: 6px;
      }
    }
  }

}

.catalogShelf {
  margin-top: 40px;
}
.header {
  color: var(--textColor);
  font-size: 14px;
  text-transform: uppercase;
  font-weight: bold;
  margin-left: 8px;
}

.slideyHeader {
  @extend .default;
}

.fixed {
  @extend .default;
  //transitionDelay: null,
  transition: all 0.3s;
  position: fixed;
  left: 0px;
  right: 0px;
  z-index:  var(--z-highest);
  bottom: 0px;
  top: auto;
  display: flex;
  opacity:1;
  background-color: var(--lighterBackgroundColor1);
  border-radius: 0px 0px 0px 0px;

  .container {
    border-radius: 8px;
    transition: all 0.3s;

    width: 100%;
    display: grid;
    margin: 4px 6px 8px 6px;
    align-self: center;
    grid-template-columns: 50px 1fr 15%;
    grid-template-rows: 20px 20px 20px;
    padding-left: 8px;
    padding-right: 6px;
    color:var(--heavyColor);

    .artistName {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      padding-right: 40px;
      grid-column: 2/3;
      grid-row: 1;
      font-size: 14px;
      font-family: "Franklin Gothic Medium", sans-serif;
    }
    .recordName {
      white-space: nowrap;
      text-overflow: ellipsis;
      padding-right: 40px;

      overflow: hidden;
      grid-column: 2/3;
      grid-row: 2;
      font-size: 14px;
      font-family: "Franklin Gothic Light", sans-serif;
    }
    .songName {
      white-space: nowrap;
      text-overflow: ellipsis;
      padding-right: 40px;
      font-weight: bold;
      overflow: hidden;
      grid-column: 2/3;
      grid-row: 3;
      font-size: 14px;
      font-family: "Franklin Gothic Light", sans-serif;
    }
    .labelName {
      white-space: nowrap;
      text-overflow: ellipsis;
      padding-right: 40px;
      opacity: 0.7;
      overflow: hidden;
      grid-column: 2/3;
      grid-row: 3;
      font-size: 14px;
      font-family: "Franklin Gothic Light", sans-serif;
    }
    .trackInfo {
      justify-self: end;
      margin-right: 9px;
      font-size: 16px;
      margin-top: -2px;
      display: flex;
      font-family: monospace;
      .trackNumber {
        margin-left: 4px;
        order: 2;
      }

      .elapsedSeconds {
        order: 1;
        //font-weight: bold;
      }
      .songName {
      }
      .timeElapsed {
      }
    }
    .controls {
      z-index:  var(--z-highest);
      grid-column: 3/4;
      grid-row: 2/4;
      justify-self: end;
      display: flex;
      color:var(--heavyColor);
      //width: 120px;
      font-size: 38px;
      justify-content: flex-end;
      margin-right: 6px;
      .playPause {
      }
    }
    .trackControls {
      z-index:  var(--z-highest);
      grid-column: 3/4;
      grid-row: 2/3;
      justify-self: end;
      justify-content: end;
      display: flex;
      width: 30px;
      font-size: 28px;
      margin-top: -5px;
    }

    .dismiss {
      //width: 50px;
      //height: 50px;
      //display: flex;
      //align-content: flex-end;
      //justify-content: flex-end;
      grid-column: 1/2;
      grid-row: 1/4;
      color:var(--heavyColor);

      .hitSlop {
        width: 50px;
        height: 50px;
        display: flex;
        align-content: flex-end;
        justify-content: flex-end;
      }
      .circle {
        margin-top: 6px;
        margin-left: -2px;
        width: 45px;
        height: 45px;
        color: var(--heavyColor);
        border-radius: 22.5px;
        display: flex;
        align-content: center;
        justify-content: center;
      }
      .icon {
        transition: transform 250ms;
        font-size: 35px;
        margin-top: -4px;
        opacity: 1;
        color:var(--heavyColor);

      }
      .icon:hover {
        opacity: 1;
      }
    }
  }
}

@media (hover: none) {
  .fixed {
    bottom: 0;
    top: auto;
  }
}


