.container {
  display: flex;
}

.step {
  display: grid;
  height: 100vh;
  background-color: lightblue;
  border: 2px solid black;
}
