.container {
  z-index: var(--z-highest);
  position: absolute;
  top:0;
  left:0;
  right:0;
  cursor: pointer;

  .shown {
    transition: opacity 1.5s;
    opacity: 1;
    background-color: #ffc;
  }

  .hidden {
    transition: opacity 1.5s;
    opacity: 0;
    background-color: #ffc;
  }
}

.notice {
  @extend .container;
  .shown {
    padding:8px;
    transition: opacity 0.5s;
  opacity: 1;
  background-color: #ffc;
}
  .hidden {
    padding:8px;
    transition: opacity 0.5s;
    opacity: 0;
    background-color: #ffc;
  }
}

.error {
  @extend .container;
  background-color: red;
  .shown {
    padding:8px;
    transition: opacity 0.5s;
    opacity: 1;
    background-color: red;
  }
  .hidden {
    padding:8px;
    transition: opacity 0.5s;
    opacity: 0;
    background-color: red;
  }
}

.playback {
  @extend .container;
  .shown {
    padding:8px;
    transition: opacity 0.5s;
    opacity: 1;
    background-color: var(--lighterBackgroundColor3);
  }
  .hidden {
    padding:8px;
    transition: opacity 0.5s;
    opacity: 0;
    background-color: var(--lighterBackgroundColor3);
  }
}



.error {
  background-color: red;
  color: black;
}
//
//@keyframes scaled {
//  from {
//    transform: scale(var(--animateScaleFrom));
//  }
//  to {
//    transform: scale(var(--animateScaleTo));
//  }
//}
//
//@keyframes unscaled {
//  from {
//    transform: scale(var(--animateScaleTo));
//  }
//  to {
//    transform: scale(var(--animateScaleFrom));
//  }
//}
