.container {
  //background-color: var(--lighterBackgroundColor1);
  //margin: 8px;
  //border-bottom: 2px dotted black; // var(--darkerBackgroundColor1);
}

.artistsContainer {
  //background-color: var(--lighterBackgroundColor1);
  margin: 4px;
}
.artistRecordsContainer {
  //background-color: var(--lighterBackgroundColor1);
  margin: 4px;
}
.artistsList {
  //margin-left:4px;
}

.artistIcon {
}

.searching {
  margin: 12px;
  font-style: italic;
}
.backButton {
  padding: 8px;
  cursor: pointer;
  font-size: 18px;
  margin-left: 4px;
  color: #777;
}
.artistName {
  font-size: 16px;
  font-weight: bold;
  margin: 4px;
  margin-top: 8px;
}

.records {
  display: grid;
}

.shelf {
  margin: 10px;
}
.queryHeader {
  margin: 10px;
  margin-bottom: 0px;
  font-style: italic;
}

.header {
  font-size: 16px;
  font-weight: bold;
  //margin: 12px;
  margin-left: 6px;
  padding-top: 6px;
}
