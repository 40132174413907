.container {
  color: var(--textColor);
  margin-top: -2px;

  //text-transform: uppercase;
  margin-bottom: -8px;
  //select {
  //  color: var(--textColor);
  //
  //  background-color: transparent; //var(--lighterBackgroundColor1);
  //  -webkit-appearance: none;
  //  border: 0px solid var(--textColor);
  //  padding: 4px;
  //  border-radius: 5px 0px 0px 5px;
  //}
  .disclosure {
    background-color: transparent; //var(--lighterBackgroundColor1);
    border-radius: 0px 5px 5px 0px;
    padding: 4px;
    padding-left: 2px;
  }
}
