.container {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  margin-bottom: 0px;
  //border-bottom: 1px solid lightgrey;
  padding-bottom: 0px;
}

.icon {
  //border-radius: 20px;
  img {
    width: 60px;
    height: 60px;
  }
}

.placeHolder {
  background-color: lightgrey;
}
.name {
  align-self: center;
  margin-left: 14px;
  font-size: 26px;
}
