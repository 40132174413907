.container {
  padding: 2px;
  margin-bottom: 6px;
  font-size: 13px;
  .header {
    font-style: italic;
    //margin: 7px ;
    //margin-top: 0px;

  }
  .playlists {
    display: flex;
    flex-wrap:wrap;
    .playlist {
      border: 1px solid var(--lighterBackgroundColor3);
      background-color: (var(--lighterBackgroundColor3));
      margin:6px;
      margin-left:0px;
      margin-bottom: 0px;
      padding: 3px;
      border-radius: 8px 6px 8px 6px;
    }
    .showAll {
      font-style: italic;
      border: 1px solid var(--lighterBackgroundColor3);
      margin:6px;
      margin-left:0px;
      margin-bottom: 0px;
      padding: 3px;
      border-radius: 8px 6px 8px 6px;
    }
  }
}