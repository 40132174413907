@import '../../stylesheets/variables.scss';
@import '../../stylesheets/mixins.scss';

.container {
  background-color:var(--lightBackgroundColor);
}
.cancel {
  float:right;
  margin-top:4px;
  margin-right:8px;
}
