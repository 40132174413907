img {
  padding: 0px;
  margin: 0px;
}

$buttoncolor: var(--backgroundColor);

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.outerContainer {
  background-color: #f6f6f6;
  padding: 10px;
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
}
.container {
  background-color: #f6f6f6;
  animation-name: fadeIn;
  animation-duration: 0.5s;

  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  margin: 10px;
  border-left: 18px solid #e62d41;
  border-bottom: 18px solid #e62d41;
}


.appStoreLink {
  margin-left:-18px;
  img {
    width:150px;
  }
}

.buttons {
  padding: 4px;
  background-color: #f6f6f6;
  border-radius: 18px;
  margin-bottom: 4px;
  border: 4px solid $buttoncolor;
  button {
    border: 0px;
    font-size: 16px;
    font-weight: bold;
    background-color: transparent;
  }
}
.try-button {
  background-color: #72d687;
  border-radius: 8px;
  margin-bottom: 4px;
}
button {
  color: $buttoncolor;
  opacity: 1;
  margin: 4px;
}

.buttonsContainer {
  position: absolute;
  right: 0px;
  bottom: 8px;
}

.logo {
  position: absolute;
  left: 18px;
  bottom: 0px;
  width: 275px;
  z-index:  var(--z-highest);
}

.bullets {
  position: absolute;
  left: 18px;
  top: 0px;
  font-weight: bold;
  .bullet {
    color: #e62d41;
    font-size: 24px;

  }
  .bullet2 {
    font-weight: normal;
    line-height: 24px;
    font-size: 16px;

  }
}
.buttons {
}

@media only screen and (max-device-width: 480px) {
  //.buttonsContainer{
  //  opacity:0.5;
  //  left:20px;
  //  top:150px;
  //}

  .appStoreLink {
    z-index: -10;
  }

  .buttons {
    padding: 4px;
    background-color: #f6f6f6;
    font-weight: bold;
    font-size: 14px;
    border-radius: 18px;
    border: 4px solid $buttoncolor;
  }
  .container {
    border-left: 9px solid #e62d41;
    border-bottom: 9px solid #e62d41;
  }
  .bullets {
    font-size: 14px;
  }
  .bullet {
  }
  .bullet2 {
    font-size: 12px;

    font-weight: normal;
    line-height: null;
  }

  button {
    border-radius: 6px;
    font-size: 14px;
    color: red; //$buttoncolor;
    font-weight: bold;
    border: 8px solid $buttoncolor;
    opacity: 0.8;
    margin: 4px;
  }
  .try-button {
    background-color: #72d687;
    border-radius: 8px;
    margin-bottom: 4px;
    padding: 4px;
    padding-left: 8px;
    padding-right: 8px;
  }
  button {
    border-radius: 6px;
    color: $buttoncolor;
    font-weight: bold;
    border: 8px solid $buttoncolor;
    opacity: 0.8;
    margin: 4px;
    padding: 4px;
    padding-left: 8px;
    padding-right: 8px;
  }
  .logo {
    width: 125px;
  }
}

@media only screen and (max-device-width: 480px) {
  //.buttonsContainer{
  //  opacity:0.5;
  //  left:20px;
  //  top:150px;
  //}
  .container {
    border-left: 9px solid #e62d41;
    border-bottom: 9px solid #e62d41;
  }
  .logo {
    width: 125px;
    bottom: -9px;
    left: 12px;
  }
}
