// generic scale animation

:root {
  --animateScaleDuration: 200ms;
  --animateScaleFrom: 1;
  --animateScaleTo: 2;
  --recordAnimationDuration: 100ms;
  --paletteTransitionDiration: 1s;
}

.scaled {
  z-index:  var(--z-highest);
  animation-duration: var(--animateScaleDuration);
  animation-name: scaled;
  animation-fill-mode: both;
  animation-direction: normal;
  animation-timing-function: ease-out;
}

.unscaled {
  z-index:  var(--z-home);
  animation-duration: var(--animateScaleDuration);
  animation-name: unscaled;
  animation-fill-mode: both;
  animation-direction: normal;
  animation-timing-function: ease-out;
}

@keyframes scaled {
  from {
    transform: scale(var(--animateScaleFrom));
  }
  to {
    transform: scale(var(--animateScaleTo));
  }
}

@keyframes unscaled {
  from {
    transform: scale(var(--animateScaleTo));
  }
  to {
    transform: scale(var(--animateScaleFrom));
  }
}

// big record flip
.turnOut {
  animation-duration: var(--turnOverDuration);
  animation-name: turnOutAnimation;
  animation-fill-mode: both;
  animation-direction: normal;
  animation-timing-function: linear;
}

@keyframes turnOutAnimation {
  from {
    transform: perspective(var(--turnOverPerspective)) rotateY(0deg);
  }
  to {
    transform: perspective(var(--turnOverPerspective)) rotateY(-90deg);
  }
}

.turnIn {
  @extend .turnOut;
  animation-name: turnInAnimation;
  animation-direction: reverse;
}

@keyframes turnInAnimation {
  from {
    transform: perspective(var(--turnOverPerspective)) rotateY(0deg);
  }
  to {
    transform: perspective(var(--turnOverPerspective)) rotateY(-90deg);
  }
}



.turnInBack {
  @extend .turnOut;
  animation-name: turnInBackAnimation;
  animation-direction: normal;
}


@keyframes turnInBackAnimation {
  from {
    transform: perspective(var(--turnOverPerspective)) rotateY(90deg);
  }
  to {
    transform: perspective(var(--turnOverPerspective)) rotateY(0deg);
  }
}


.turnOutBack {
  @extend .turnOut;
  animation-name: turnOutBackAnimation;
  animation-direction: reverse;
}


@keyframes turnOutBackAnimation {
  from {
    transform: perspective(var(--turnOverPerspective)) rotateY(90deg);
  }
  to {
    transform: perspective(var(--turnOverPerspective)) rotateY(0deg);
  }
}
