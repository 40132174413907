@import "../../stylesheets/variables.scss";
@import "../../stylesheets/mixins.scss";
.default {
  @include scrollbars(0px, transparent, transparent);
  background-color: transparent; // var(--lighterBackgroundColor3);
  height:100%;
  margin-top: 0px;
  margin-bottom: 70px;
  .cover {
    width: 100px;
  }
  .textAndControls {
    display: none;
    .id {
      display: inline-block;
    }
    .text {
      .artistName {
      }
      .recordName {
      }
    }
    .controls {
      .prev {
      }
      .play {
      }
      .pause {
      }
      .next {
      }
    }
  }

  .cancel {
    float: right;
    margin-top: 4px;
    margin-right: 8px;
  }
}

.catalogShelf {
}

.contextRecords {
  padding: 0px;
  margin-top: 0px;
  margin-bottom: 100px;
  //background-color: var(--lighterBackgroundColor1);

}

.contextRecord {
  margin-top: 0px;

}

.sectionHeader {
  color: var(--textColor);
  font-size: 13px;
  text-transform: uppercase;
  font-weight: bold;
  margin-left: 2px;
  margin-bottom: 5px;
}

.header {
  color: var(--textColor);
  font-size: 14px;
  text-transform: uppercase;
  font-weight: bold;
  margin-left: 8px;
}

.bigSwipeCover {
  position: absolute;
  left:0;
  top:0;
  right:0;
  bottom:0;
  z-index: var(--z-selection);
  //background-color: #0E52CE;

  @extend .default;
  .cover {
    margin-top: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: black;
    padding-top: 44px;
    padding-bottom: 16px;
    img {
      align-self: center;
      max-height: 1000px;
      max-width: 1000px;
      padding: 0px;
      margin: 0px;
    }
  }
  .textAndControls {
    //background-color: var(--backgroundColor);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index:  var(--z-highest);
    width: 100%;
    display: grid;
    grid-template-columns: 65% 35%;
    grid-template-rows: 20px 20px 20px;
    padding-left: 8px;
    padding-right: 6px;
    height: 62px;
    .artistName {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      grid-column: 1/2;
      grid-row: 1;
      font-size: 14px;
      font-family: "Franklin Gothic Medium", sans-serif;
    }
    .recordName {
      white-space: nowrap;
      text-overflow: ellipsis;

      overflow: hidden;
      grid-column: 1/2;
      grid-row: 2;
      font-size: 14px;
      font-family: "Franklin Gothic Light", sans-serif;
    }
    .songName {
      white-space: nowrap;
      text-overflow: ellipsis;

      overflow: hidden;
      grid-column: 1/2;
      grid-row: 3;
      font-size: 14px;
      font-family: "Franklin Gothic Light", sans-serif;
    }
    .trackInfo {
      justify-self: end;
      margin-right: 2px;
      font-size: 14px;
      display: flex;
      font-family: monospace;
      .trackNumber {
        margin-left: 4px;
        order: 2;
      }
      .trackNumber::before {
        content: "(";
      }
      .trackNumber::after {
        content: ")";
      }

      .elapsedSeconds {
        order: 1;
        //font-weight: bold;
      }
      .songName {
      }
      .timeElapsed {
      }
    }
    .controls {
      grid-column: 2/3;
      grid-row: 2/3;
      justify-self: end;
      display: flex;
      width: 120px;
      font-size: 28px;
      justify-content: space-between;
      margin-top: -5px;
      .prev {
      }
      .play {
        //display:none
      }
      .pause {
        //display:none
      }
      .stop {
      }
      .next {
      }
    }
  }
}


.dismiss {
  border:5px solid red;
  position: absolute;
  opacity:0.5;
  right: 10px;
  bottom: 28px;
  z-index:  var(--z-highest);
  //width: 50px;
  //height: 50px;
  //display: flex;
  //align-content: flex-end;
  //justify-content: flex-end;
  .hitSlop {
    width: 50px;
    height: 50px;
    display: flex;
    align-content: flex-end;
    justify-content: flex-end;
  }
  .circle {
    opacity: 0.8;
    width: 30px;
    height: 30px;
    background-color: var(--lighterBackgroundColor2);
    color: var(--heavyColor);
    border-radius: 15px;
    display: flex;
    align-content: center;
    justify-content: center;
  }
  .icon {
    transform: translate(0, -9);
    font-size: 28px;
    margin-top: -7px;
    transition: all 0.1s;
    opacity: 1;
  }
  .icon:hover {
    opacity: 1;
  }
}
