@import './variables.scss';

@mixin large-title {
  font-size: 1.6em;
  font-weight: 700;
  color: $black;
}

@mixin album-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(160px, 190px));
  grid-column-gap: 30px;
  grid-row-gap: 30px;
}

@mixin scrollbars($size, $foreground-color, $background-color: mix($foreground-color, white,  50%)) {
  // For Google Chrome
  &::-webkit-scrollbar {
    padding:2px;
    width:  $size;
    height: $size;
  }
  &::-webkit-scrollbar:hover {
    padding:2px;
    width:  $size;
    height: $size;
  }

  &::-webkit-scrollbar-thumb {
    background: $foreground-color;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: $background-color;
  }

  // For Internet Explorer
  & {
    scrollbar-face-color: $foreground-color;
    scrollbar-track-color: $background-color;
  }
}
