.container {
}

.record {
  font-size:12px;
  flex-wrap:wrap;
  width:100%;
}

h2 {
  margin-left:4px;
  padding-top:10px;
  margin-bottom:10px;
}
.record-spine {
  width:100%;
  display: flex;
  justify-content: center;
  margin-bottom:4px;
}
.record-artist-name {
  width:70%;
  font-size:10px;
  height:15px;
  overflow:hidden;

}
.now-playing-record {
  opacity:0.7
}
.record-cover {

}
.list {
}

a {
  color: #555
}

.container{
  display:grid;
  margin-bottom:12px;
  margin-left:4px;
}


.cover {
}


.text-container {
  display:grid;
  margin-left:4px;
  flex-direction: row;
  justify-content: space-between;
}


.artist-name {
  width:90%;
  padding-left:4px;
  font-weight:bold;
  line-height: 16px;
  font-size:14px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow:ellipsis;
}
.label {
  padding-left:4px;
  color:#555;
  font-size:12px;
  width:100%;
  height:15px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow:ellipsis;
}

.release-date {
  padding-left:4px;
  color:#777;
  font-size:12px;
  width:100%;
  height:15px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow:ellipsis;
}
.name {
  width:90%;
  padding-left:4px;
  font-size:12px;
  line-height: 16px;
  height:15px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow:ellipsis;
}
.add-button {
  margin-right:4px;
  font-size:28px;
}


.coverPlaceHolder {
  background: lightgrey;
  width: 90px;
  height:90px;
}


.add-button-hidden {
  margin-right:10px;
  font-size:28px;
  opacity:0;
}

@media (hover: none) {
  .add-button-hidden {
    opacity:1;
  }
}
