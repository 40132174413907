/*# sourceMappingURL=home.module.scss.map */
.wrapWrap {
  display: flex;
  //margin:18px;
  padding-top: 34px;
  background-color: #f6f6f6;
  height: 2500px;
  justify-content: center;
}

.wrap {
  border-radius: 24px;
  height: 500px;
  // margin:20px;
  display: flex;
  padding-left: 16px;
  justify-content: center;
  flex-direction: column;
  background-color: var(--backgroundColor);
}

.storyContainer {
  max-width: 360px;
  font-family: "Georgia";
  padding: 8px;
  font-size: 16px;
  line-height: 32px;
  //font-style:italic;
  img {
    border-radius: 9px;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.container {
  z-index: var(--z-home);
  //flex-direction: column;
  animation-name: fadeIn;
  animation-duration: 0.5s;
  padding-top: envx(safe-area-inset-top, 20px);
  display: flex;
  background-color: var(--backgroundColor);
  overscroll-behavior: none;
  overflow-y: hidden;
  transition: background-color 1s;
  margin-bottom: 60px;

  h1 {
    font-weight: bold;
    font-size: 28px;
    margin-left: 12px;
    padding-top: 10px;
    margin-bottom: 0px;
    margin-top: 5px;
  }
}

.loadingStep {
  opacity: 0.5;
  z-index: calc(var(--z-home) + 1);
  right: 14px;
  top: 14px;
  border-radius: 12px;
  padding: 3px;
  color: var(--lighterText);
  text-transform: uppercase;
  font-size: 10px;
  font-weight: bold;
  position: absolute;
  background-color: var(--lighterBackgroundColor2);
  border: 2px dotted var(--backgroundColor);
}


.header {
  //@extend .header1;
  transition: opacity var(--recordAnimationDuration);
  .logoContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    .logoWrap {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      .point {
        display: flex;
        flex-direction: row;
        border-radius: 2px;
        background-color: #f6f6f6;
        border: 6px;
        transform: translateY(8px);
        color: var(--lighterBackgroundColor2);
        font-weight: bold;
        font-style: italic;
      }

      .logo {
        margin: 0px;
        padding: 0px 13px 0px 8px;
        background-color: #f5f5f5;
        //border-radius: 35px;
        //border: 5px solid black;

        img {
          width: 180px;
          height: 180px;
        }
      }


    }

  }
  @media only screen and (max-device-width: 480px) {
    .logoContainer {
      .logoWrap {
        .point {
          font-size: 12px;
          margin-left: 6px;
        }
        .logo {
          padding: 0px 13px 0px 0px;

        }
      }
    }

  }
}
.pickedItem {

}


.footer {
  transition: opacity var(--recordAnimationDuration);
  .buttonsBackground {
    display: none;
    background-color: var(--shelfBlueColor);
    position: absolute;
    bottom: 0px;
    left:0px;
    right: 0px;
    //height: 60px;
    z-index: calc(var(--z-highest) - 1);
    opacity: 1;

  }

  .buttonsContainer {
    position: absolute;
    bottom: 0px;
    left:0;
    right: 0px;
    //height: 60px;
    background-color: var(--backgroundColor);

    z-index: calc(var(--z-highest) - 1);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px;
    opacity: 1;


    .buttons {
      transform: translateY(-3px);
      width:450px;

      button {
        width:100%;
        opacity: 1;
        padding: 4px 8px 4px 8px;
        z-index: var(--z-highest);
        border-radius: 8px;
        border: 2px solid var(--shelfBlueColor); //rgb(230,61,68);
        color: var(--shelfBlueColor); //rgb(230,61,68);
        background-color: var(--backgroundColor);
        font-size: 16px;
        font-weight: bold;
      }
    }
  }

}


.logoutButton {
  margin-left: 6px;
  background-color: transparent;
  border: 6px;
  color: var(--lighterBackgroundColor1);
  font-weight: bold;
}

.logoutButtonScarce {
  margin-left: 6px;
  background-color: transparent;
  border: 6px;
  color: var(--lighterBackgroundColor1);
  font-weight: bold;
}

.instructions {
  font-family: "Helvetica";
  font-weight: bold;
}

.signature {
  margin-right: 40px;
  font-emphasis: italic;
  display: flex;
  justify-content: flex-start;
}

img {
  justify-self: center;
  width: 90%;
  margin-left: 6px;
  margin-top: 15px;
  margin-bottom: 15px;
  //border:#f6f6f6 10px solid;
}

//.buttons {
//  display: flex;
//  width: 200px;
//  padding: 2px;
//  border-radius: 6px;
//  background-color: #f6f6f6;
//  justify-content: center;
//  margin-left: 70px;
//}

button {
  //border-radius: 10px;
  //background-color:white;
  //border-color:lightgrey;
  align-self: flex-start;
}

.appleMusicLogo {
  width: 100px;
  align-self: center;
  margin-bottom: 50px;
  margin-left: 11px;
}
